// src/videoConfig.js

export const videos = {
  genomics: {
    title: "基因组学",
    description: "解码生命的蓝图，探索DNA的无限可能。",
    content: [
      {
        id: "192a6af6-f84c-45d3-873a-c2401431da66",
        title: "什么是DNA?",
        materials: [
          { type: "幻灯片", link: "#", description: "基因组学基础概念幻灯片" },
          { type: "习题", link: "#", description: "基因组学基础练习题" },
          { type: "作业", link: "#", description: "基因组学基础研究报告" }
        ]
      },
      {
        id: "a3dd7c26-7b1e-4a1f-8b7f-332e4211394b",
        title: "什么是基因？",
        materials: [
          { type: "幻灯片", link: "#", description: "基因组学高级应用幻灯片" },
          { type: "实验指南", link: "#", description: "基因组学实验指南" },
          { type: "作业", link: "#", description: "基因组学研究项目提案" }
        ]
      }
    ]
  },
  cellbiology: {
    title: "细胞生物学",
    description: "深入细胞内部，揭示生命的基本单位。",
    content: [
      {
        id: "2745c35a-434a-4f70-9367-2b2382613685",
        title: "什么是细胞？",
        materials: [
          { type: "幻灯片", link: "#", description: "基因组学基础概念幻灯片" },
          { type: "习题", link: "#", description: "基因组学基础练习题" },
          { type: "作业", link: "#", description: "基因组学基础研究报告" }
        ]
      },
      {
        id: "e5f3afe4-49fe-455d-88ff-65e040d0601b",
        title: "细胞内部构造",
        materials: [
          { type: "幻灯片", link: "#", description: "基因组学基础概念幻灯片" },
          { type: "习题", link: "#", description: "基因组学基础练习题" },
          { type: "作业", link: "#", description: "基因组学基础研究报告" }
        ]
      }
    ]  // 暂时为空，可以后续添加视频
  },
  syntheticbiology: {
    title: "AI与教育",
    description: "如何让AI帮助实际教学",
    content: [
      {
        id: "1a113436-a1d0-4bac-882a-495cba0a5ab7",
        title: "如何让AI拯救而不是摧毁教育",
      }
    ]  // 暂时为空，可以后续添加视频
  },
  neuroscience: {
    title: "神经科学",
    description: "探索大脑的奥秘，解密思维和意识。",
    content: [
      {
        id: "045acca5-bbd5-43f6-8093-66db15513020",
        title: "脑神经的发育过程",

      }
    ]  // 暂时为空，可以后续添加视频
  },
  biofood: {
    title: "生物教研",
    description: "探索紧跟时代的生物教研",
    content: [
      {
        id: "949c4242-5976-4446-b086-f52b429997bf",
        title: "生新课程理念下初中生物听评课和教学反思",
        materials: [
        ]
      },
      {
        id: "8ace7b02-48a3-4d02-af4c-e9e868e7f596",
        title: "单元学习设计 从知识到素养的桥梁",
        materials: [
        ]
      }
    ]
  },
  biotechnology: {
    title: "毕业季",
    description: "祝大家前程似锦",
    content: [
      {
        id: "71b393a8-8bff-4f0e-888a-fbc3cdab27b5",
        title: "祝大家一路顺风",
        materials: [
        ]
      }
    ]  // 暂时为空，可以后续添加视频
  }
};