import React, { useState, useEffect, useRef } from 'react';
import { Volume2, VolumeX } from 'lucide-react';

const MorningReadingDetector = () => {
  const [decibels, setDecibels] = useState(0);
  const [isListening, setIsListening] = useState(false);
  const canvasRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const rafIdRef = useRef(null);
  const ballsRef = useRef([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight * 0.6;

    // Initialize balls
    for (let i = 0; i < 20; i++) {
      ballsRef.current.push({
        x: Math.random() * canvas.width,
        y: canvas.height,
        radius: Math.random() * 20 + 10,
        color: `hsl(${Math.random() * 360}, 70%, 50%)`,
        velocity: 0,
      });
    }

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ballsRef.current.forEach((ball) => {
        // Apply gravity
        ball.velocity += 0.2;
        ball.y += ball.velocity;

        // Bounce off bottom
        if (ball.y + ball.radius > canvas.height) {
          ball.y = canvas.height - ball.radius;
          ball.velocity *= -0.8;
        }

        // Draw ball
        ctx.beginPath();
        ctx.arc(ball.x, ball.y, ball.radius, 0, Math.PI * 2);
        ctx.fillStyle = ball.color;
        ctx.fill();
        ctx.closePath();

        // Add 3D effect
        const gradient = ctx.createRadialGradient(
          ball.x - ball.radius * 0.3,
          ball.y - ball.radius * 0.3,
          ball.radius * 0.2,
          ball.x,
          ball.y,
          ball.radius
        );
        gradient.addColorStop(0, 'rgba(255, 255, 255, 0.8)');
        gradient.addColorStop(1, ball.color);
        ctx.fillStyle = gradient;
        ctx.fill();
      });

      rafIdRef.current = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      cancelAnimationFrame(rafIdRef.current);
    };
  }, []);

  const startListening = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);
      analyserRef.current.fftSize = 256;
      dataArrayRef.current = new Uint8Array(analyserRef.current.frequencyBinCount);
      setIsListening(true);
      updateNoiseLevel();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopListening = () => {
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    setIsListening(false);
    setDecibels(0);
  };

  const updateNoiseLevel = () => {
    analyserRef.current.getByteFrequencyData(dataArrayRef.current);
    const average = dataArrayRef.current.reduce((acc, value) => acc + value, 0) / dataArrayRef.current.length;
    const db = Math.round((average / 255) * 100);
    setDecibels(db);

    // Update ball positions based on sound level
    ballsRef.current.forEach((ball) => {
      ball.velocity -= (db / 100) * 5;
    });

    rafIdRef.current = requestAnimationFrame(updateNoiseLevel);
  };

  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-120px)] bg-gray-900 text-white p-4">
      <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-blue-400">晨读声音探测器</h2>
      <canvas ref={canvasRef} className="w-full max-w-3xl h-96 bg-black rounded-lg mb-4" />
      <p className="text-lg mb-4">声音强度: {decibels} dB</p>
      <button
        onClick={isListening ? stopListening : startListening}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex items-center text-sm"
      >
        {isListening ? (
          <>
            <VolumeX className="mr-2" size={18} /> 停止检测
          </>
        ) : (
          <>
            <Volume2 className="mr-2" size={18} /> 开始检测
          </>
        )}
      </button>
    </div>
  );
};

export default MorningReadingDetector;