import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useNavigate } from 'react-router-dom';
import { Dna, Microscope, Leaf, Brain, Utensils, ChevronRight, FlaskConicalIcon, FileText, BookOpen, PenTool, Menu} from 'lucide-react';
import { videos } from './videoConfig';
import RollCall from './RollCall';
import NoiseDetector from './NoiseDetector';
import MorningReadingDetector from './MorningReadingDetector';


const BioTechLandingPage = () => {
  const [isToolOpen, setIsToolOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsToolOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-900 to-black text-white font-sans">
        <header className="container mx-auto px-4 py-6">
          <nav className="flex justify-between items-center">
            <Link to="/" className="text-2xl md:text-3xl font-bold text-green-400">生物课堂</Link>
            <div className="relative" ref={menuRef}>
              <button onClick={() => setIsToolOpen(!isToolOpen)} className="text-white p-2 rounded-full bg-gray-700 hover:bg-gray-600">
                <Menu size={24} />
              </button>
              {isToolOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-gray-800 rounded-md shadow-lg py-1">
                  <Link to="/roll-call" className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-700" onClick={() => setIsToolOpen(false)}>点名工具</Link>
                  <Link to="/noise-detector" className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-700" onClick={() => setIsToolOpen(false)}>噪音检测器</Link>
                  <Link to="/morning-reading" className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-700" onClick={() => setIsToolOpen(false)}>晨读声音检测器</Link>
                </div>
              )}
            </div>
          </nav>
        </header>

        <main className="container mx-auto px-4 py-8 md:py-12 flex-grow">
          <Routes>
            <Route path="/" element={<HomePage />} />
            {Object.entries(videos).map(([key, value]) => (
              <Route
                key={key}
                path={`/${key}`}
                element={<VideoList category={value.title} videos={value.content} />}
              />
            ))}
            <Route path="/roll-call" element={<RollCall />} />
            <Route path="/noise-detector" element={<NoiseDetector />} />
            <Route path="/morning-reading" element={<MorningReadingDetector />} />
          </Routes>
        </main>

        <footer className="bg-gray-800 py-6 mt-auto">
          <div className="container mx-auto px-4 text-center text-gray-400">
            <p>&copy; 2024 生物课堂。</p>
          </div>
        </footer>
      </div>
    </Router>
  );
};

const HomePage = () => {
  const navigate = useNavigate();

  const icons = {
    genomics: Dna,
    cellbiology: Microscope,
    syntheticbiology: Leaf,
    neuroscience: Brain,
    biofood: Utensils,
    biotechnology: FlaskConicalIcon
  };

  return (
    <>
      <section className="text-center mb-12 md:mb-20">
        <h2 className="text-3xl md:text-5xl font-bold mb-4 md:mb-6 text-green-400">
          学习生物科学
        </h2>
        <p className="text-lg md:text-xl mb-6 md:mb-8 max-w-2xl mx-auto">
          探索生命的奥秘 自然的规律
        </p>
        <button className="bg-green-500 text-black font-bold py-2 md:py-3 px-6 md:px-8 rounded-full inline-flex items-center hover:bg-green-400 transition-colors text-sm md:text-base">
          加入我们的探索 <ChevronRight className="ml-2" />
        </button>
      </section>

      <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-12 md:mb-20">
        {Object.entries(videos).map(([key, value]) => {
          const Icon = icons[key];
          return (
            <FeatureCard 
              key={key}
              icon={<Icon className="w-6 h-6 md:w-8 md:h-8" />}
              title={value.title}
              description={value.description}
              onClick={() => navigate(`/${key}`)}
            />
          );
        })}
      </section>
    </>
  );
};

const FeatureCard = ({ icon, title, description, onClick }) => {
  return (
    <div className="bg-gray-800 p-4 md:p-6 rounded-lg hover:bg-gray-700 transition-colors cursor-pointer" onClick={onClick}>
      <div className="mb-3 md:mb-4 text-green-400">{icon}</div>
      <h3 className="text-lg md:text-xl font-semibold mb-2">{title}</h3>
      <p className="text-sm md:text-base text-gray-300">{description}</p>
    </div>
  );
};

const VideoList = ({ category, videos }) => {
  return (
    <div className="max-w-6xl mx-auto">
      <h2 className="text-2xl md:text-4xl font-bold mb-6 text-green-400">{category}</h2>
      {videos.length > 0 ? (
        videos.map((video, index) => (
          <VideoContent key={index} videoId={video.id} title={video.title} materials={video.materials} />
        ))
      ) : (
        <PlaceholderContent title={category} />
      )}
    </div>
  );
};

const VideoContent = ({ videoId, title, materials }) => {
  return (
    <div className="mb-8 md:mb-12">
      <h3 className="text-xl md:text-2xl font-bold mb-3 md:mb-4 text-green-400">{title}</h3>
      <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg mb-4 md:mb-6">
        <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
          <iframe
            title={title}
            src={`https://v.english.vg/videos/embed/${videoId}`}
            frameBorder="0"
            allowFullScreen
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            className="absolute top-0 left-0 w-full h-full"
          ></iframe>
        </div>
      </div>
      {materials && materials.length > 0 && (
        <div className="bg-gray-800 rounded-lg p-4 md:p-6 shadow-lg">
          <h4 className="text-lg md:text-xl font-semibold mb-3 md:mb-4 text-green-400">学习资料</h4>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-4">
            {materials.map((material, index) => (
              <MaterialCard key={index} {...material} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const MaterialCard = ({ type, link, description }) => {
  const icons = {
    "幻灯片": FileText,
    "习题": BookOpen,
    "作业": PenTool,
    "实验指南": FlaskConicalIcon
  };
  const Icon = icons[type] || FileText;

  return (
    <a href={link} className="bg-gray-700 rounded-lg p-3 md:p-4 flex items-start space-x-3 md:space-x-4 hover:bg-gray-600 transition-colors">
      <div className="bg-green-500 rounded-full p-2">
        <Icon className="w-4 h-4 md:w-6 md:h-6 text-white" />
      </div>
      <div>
        <h4 className="text-base md:text-lg font-semibold text-white mb-1">{type}</h4>
        <p className="text-xs md:text-sm text-gray-300">{description}</p>
      </div>
    </a>
  );
};

const PlaceholderContent = ({ title }) => {
  return (
    <div className="max-w-4xl mx-auto text-center">
      <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-green-400">{title}</h2>
      <p className="text-lg md:text-xl mb-6 md:mb-8">
        我们正在为这个领域准备精彩的内容。请稍后再来查看！
      </p>
      <Link to="/" className="bg-green-500 text-black font-bold py-2 md:py-3 px-6 md:px-8 rounded-full inline-flex items-center hover:bg-green-400 transition-colors text-sm md:text-base">
        返回首页 <ChevronRight className="ml-2" />
      </Link>
    </div>
  );
};

export default BioTechLandingPage;