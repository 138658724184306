import React, { useState, useEffect, useRef } from 'react';
import { Volume2, VolumeX } from 'lucide-react';

const NoiseDetector = () => {
  const [decibels, setDecibels] = useState(0);
  const [isListening, setIsListening] = useState(false);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const rafIdRef = useRef(null);
  const prevDbRef = useRef(0);

  useEffect(() => {
    return () => {
      if (rafIdRef.current) {
        cancelAnimationFrame(rafIdRef.current);
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
    };
  }, []);

  const startListening = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      const source = audioContextRef.current.createMediaStreamSource(stream);
      source.connect(analyserRef.current);
      analyserRef.current.fftSize = 2048;
      analyserRef.current.minDecibels = -100;
      analyserRef.current.maxDecibels = 0;
      analyserRef.current.smoothingTimeConstant = 0.5;
      dataArrayRef.current = new Float32Array(analyserRef.current.frequencyBinCount);
      setIsListening(true);
      setDecibels(0);
      prevDbRef.current = 0;
      rafIdRef.current = requestAnimationFrame(updateNoiseLevel);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopListening = () => {
    if (rafIdRef.current) {
      cancelAnimationFrame(rafIdRef.current);
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    setIsListening(false);
    setDecibels(0);
    prevDbRef.current = 0;
  };

  const updateNoiseLevel = () => {
    analyserRef.current.getFloatFrequencyData(dataArrayRef.current);
    
    // Calculate the RMS (Root Mean Square) of the frequency data
    const rms = Math.sqrt(
      dataArrayRef.current.reduce((sum, value) => sum + Math.pow(10, value / 10), 0) / dataArrayRef.current.length
    );

    // Convert to decibels with increased sensitivity and safeguard against -Infinity
    const db = Math.max(0, 20 * Math.log10(Math.max(rms, 1e-6)) + 130);
    
    // Apply a more responsive smoothing
    const smoothingFactor = 0.3;
    const smoothedDb = prevDbRef.current * smoothingFactor + db * (1 - smoothingFactor);
    
    setDecibels(Math.round(smoothedDb));
    prevDbRef.current = smoothedDb;
    
    rafIdRef.current = requestAnimationFrame(updateNoiseLevel);
  };

  const getColor = () => {
    if (decibels < 40) return '#4ade80'; // green
    if (decibels < 70) return '#facc15'; // yellow
    return '#ef4444'; // red
  };

  const getEmoji = () => {
    if (decibels < 40) return '😊';
    if (decibels < 70) return '😟';
    return '😠';
  };

  // Calculate the arc length for the progress circle
  const getArcLength = () => {
    const maxDb = 100; // Maximum dB value for the circle
    const percentage = Math.min(decibels / maxDb, 1);
    return percentage * 283; // 283 is the full circumference of the circle (2 * π * 45)
  };

  return (
    <div className="flex flex-col items-center justify-center h-[calc(100vh-120px)] bg-gray-900 text-white p-4">
      <h2 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">课堂噪音检测器</h2>
      <div className="relative w-64 h-64 sm:w-80 sm:h-80 mb-4 sm:mb-6">
        <svg className="w-full h-full" viewBox="0 0 100 100">
          <circle
            cx="50"
            cy="50"
            r="45"
            fill="none"
            stroke="#333"
            strokeWidth="8"
          />
          <circle
            cx="50"
            cy="50"
            r="45"
            fill="none"
            stroke={getColor()}
            strokeWidth="8"
            strokeDasharray={`${getArcLength()} 283`}
            transform="rotate(-90 50 50)"
          />
          <text
            x="50"
            y="50"
            textAnchor="middle"
            dy=".3em"
            className="text-4xl sm:text-5xl"
          >
            {getEmoji()}
          </text>
        </svg>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 text-xl font-bold">
          {isListening ? `${decibels} dB` : '待机中'}
        </div>
      </div>
      <button
        onClick={isListening ? stopListening : startListening}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex items-center text-sm sm:text-base"
      >
        {isListening ? (
          <>
            <VolumeX className="mr-2" size={20} /> 停止检测
          </>
        ) : (
          <>
            <Volume2 className="mr-2" size={20} /> 开始检测
          </>
        )}
      </button>
    </div>
  );
};

export default NoiseDetector;