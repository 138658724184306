import React, { useState } from 'react';

// 在这里定义学生名单
const studentList = [
    "张三", "李四", "王五", "赵六", "钱七", "孙八", "周九", "吴十",
    "郑十一", "王十二", "冯十三", "陈十四", "褚十五", "卫十六", 
    "蒋十七", "沈十八", "韩十九", "杨二十", "朱二十一", "秦二十二"
];

const RollCall = () => {
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const selectRandomStudent = () => {
    setIsSelecting(true);
    setShowResult(false);
    const interval = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * studentList.length);
      setSelectedStudent(studentList[randomIndex]);
    }, 100);

    setTimeout(() => {
      clearInterval(interval);
      setIsSelecting(false);
      setShowResult(true);
    }, 3000);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h2 className="text-3xl font-bold mb-6 text-green-400">点名工具</h2>
      <div className="text-center mb-8">
        <button
          onClick={selectRandomStudent}
          disabled={isSelecting}
          className="bg-purple-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-purple-500 transition-colors disabled:bg-gray-600"
        >
          随机抽查
        </button>
      </div>
      {selectedStudent && (
        <div className={`mt-8 text-center transition-all duration-1000 ease-out ${
          showResult ? 'opacity-100 scale-100' : 'opacity-0 scale-95'
        } ${isSelecting ? 'animate-pulse' : ''}`}>
          <h3 className="text-2xl font-bold text-yellow-400">
            被选中的学生：
          </h3>
          <p className="text-4xl mt-2 text-white">
            {selectedStudent}
          </p>
        </div>
      )}
      <div className="mt-12 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {studentList.map((student, index) => (
          <div key={index} className="bg-gray-700 p-2 rounded-md text-center">
            {student}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RollCall;